import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard/main',
    title: 'Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'dashboard',
   // donut_small
   //restore
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    role : ["0"]
  },
  {
    path: '/hr/hr',
    title: 'HR',
    iconType: 'material-icons-two-tone',
    icon:'business_center',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
     role : ["0"]
  },
  // {
  //   path: '/dashboard/userdashboard',
  //   title: 'Users Dashboard',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'airplay',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },

  // {
  //   path: '/advance-table/allemployee',
  //   title: 'All Employees',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'account_circle',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: 'badge bg-blue sidebar-badge float-end',
  //   submenu: [],
  //   role : ["0"]
  // },
  {
    path: '/organization/organizationdetails',
    title: 'Organization',
    iconType: 'material-icons-two-tone',
    icon: 'account_balance',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-blue sidebar-badge float-end',
    submenu: [],
    role : ["0"]
  },
  {
    path: '/advance-table/setting',
    title: 'Settings',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-blue sidebar-badge float-end',
    submenu: [],
    role : ["0"]
  },

  // {
  //   path: 'calendar',
  //   title: 'Calendar',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'event_note',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: 'badge bg-blue sidebar-badge float-end',
  //   submenu: [],
  //   role : ["0"]
  // },

  // {
  //   path: 'calendar',
  //   title: 'Calendar',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'event_note',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: 'badge bg-blue sidebar-badge float-end',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },
  // {
  //   path: 'managevms',
  //   title: 'Manage VMs',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'table_view',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {

  //     path: '/extra-pages/blank',
  //     title: 'Blank Page',
  //     iconType: 'material-icons-two-tone',
  //     icon: 'table_view',
  //     class: 'ml-menu',
  //     groupTitle: false,
  //     badge: '',
  //     badgeClass: '',
  //     submenu: [],
  //   role : ["0"]
  // },

  // {
  //   path: '/timeline/timeline1',
  //   title: 'Approve WeeklyTimesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'table_view',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/task/tasktimesheetComponent',
  //   title: 'Timesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'schedule',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/task/tasktimesheetComponent',
  //   title: 'Timesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'schedule',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },
  // {
  //   path: '/task/weekuser',
  //   title: 'weekuserTimesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'schedule',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: 'task',
  //   title: 'weeklytimesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'schedule',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/task/admintimesheet',
  //   title: 'Admin Timesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'schedule',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: 'contacts',
  //   title: 'Contacts',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'contacts',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: 'project',
  //   title: 'Project',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'book',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: 'designation',
  //   title: 'Designation',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'school',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/leave/applyleave',
  //   title: 'Apply Leave',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'border_color',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },
  // {
  //   path: '/leave/approveleave',
  //   title: 'Approve Leaves',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'approval',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/leave/leavelist',
  //   title: 'Leaves List',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'approval',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },

  // {
  //   path: '/timesheet/timesheet',
  //   title: 'Timesheet',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'access_time',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },
  // {
  //   path: '/payslip/payslip',
  //   title: 'Payslip',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'payments',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["1","2","3"]
  // },
  // {
  //   path: '/timesheet/approvetimesheet',
  //   title: 'Approve Timesheets',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'approval',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/payslip/generatepayslip',
  //   title:'Generate Payslip',
  //   iconType:'material-icons-two-tone',
  //   icon:'payments',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '/payslip/payroll',
  //   title: 'Payroll',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'payment',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]
  // },
  // {
  //   path: '',
  //   title: '',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  //   role : ["0"]

  // },
];
